import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Label, Input, Textarea, Button, Message, Spinner } from 'theme-ui'
const axios = require('axios')

/**
 * How to enable form integration:
 *
 * 1) Shadow this component
 * 2) Remove the demo attribute from the form tag.
 * 3) Add your action end-point to the form tag.
 * 4) If required by your form API provider, add the hidden input(s).
 *
 * Some recommended serverless form providers:
 * Getform (https://www.gatsbyjs.com/docs/building-a-contact-form/#getform)
 * Formspree (https://www.gatsbyjs.com/docs/building-a-contact-form/#formspree)
 * Netlify Form (https://www.gatsbyjs.com/docs/building-a-contact-form/#netlify)
 *
 */

const ContactForm = ({ handleSubmit, submitting, success = true }) => {
  function SubmitForm(e) {
    console.log(name)
    const obj = {
      name,
      companyName,
      subject,
      phoneNumber,
      message,
      email
    }
    e.preventDefault()
    e.stopPropagation()
    const config = {
      headers: { Authorization: `Bearer ${process.env.GATSBY_TOKEN}` }
    }
    axios
      .post(`${process.env.GATSBY_STRAPI_API_URL}/api/email`, obj, config)
      .then(response => {
        showSuccessMessage(true)
        setName('')
        setCompanyName('')
        setMessage('')
        setPhoneNumber('')
        setEmail('')
        setSubject('')
        setTimeout(() => {
          showSuccessMessage(false)
        }, 2000)
      })
      .catch(err => {
        setTimeout(() => {
        showErrorMessage(false)
        },2000)
      })
  }
  const [name, setName] = useState()
  const [companyName, setCompanyName] = useState()
  const [email, setEmail] = useState()
  const [subject, setSubject] = useState()
  const [phoneNumber, setPhoneNumber] = useState()
  const [message, setMessage] = useState()
  const [successMessage, showSuccessMessage] = useState(false)
  const [errorMessage, showErrorMessage] = useState(false)
  return (
    <form
      onSubmit={SubmitForm}
      method='POST'
      action='YOUR_ACTION_END_POINT'
      demo='demo'
    >
      {successMessage === true && (
        <Message variant='success'>
          Thank you for contacting us. We'll get back to you soon!
        </Message>
      )}
      {errorMessage === true && (
        <Message variant='error'>
          Something went wrong. Please try again later!
        </Message>
      )}
      <Box variant='forms.row'>
        <Box variant='forms.column'>
          <Label htmlFor='contact-form-name'>Name</Label>
          <Input
            type='text'
            value={name}
            onChange={e => setName(e.target.value)}
            id='contact-form-name'
            name='name'
            required
          />
        </Box>
        <Box variant='forms.column'>
          <Label htmlFor='contact-form-company'>Company Name</Label>
          <Input
            value={companyName}
            onChange={e => setCompanyName(e.target.value)}
            type='text'
            id='contact-form-company'
            name='company'
          />
        </Box>
      </Box>
      <Box variant='forms.row'>
        <Box variant='forms.column'>
          <Label htmlFor='contact-form-email'>Email</Label>
          <Input
            type='email'
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder='email@example.com'
            id='contact-form-email'
            name='email'
            required
          />
        </Box>
        <Box variant='forms.column'>
          <Label htmlFor='contact-form-phone'>Phone Number</Label>
          <Input
            type='tel'
            value={phoneNumber}
            onChange={e => setPhoneNumber(e.target.value)}
            placeholder='(xxx) xxx-xxxx'
            id='contact-form-phone'
            name='phone'
          />
        </Box>
      </Box>
      <Box variant='forms.row'>
        <Label htmlFor='contact-form-subject'>Subject</Label>
        <Input
          type='text'
          value={subject}
          onChange={e => setSubject(e.target.value)}
          id='contact-form-subject'
          name='subject'
          required
        />
      </Box>
      <Box variant='forms.row'>
        <Label htmlFor='contact-form-message'>Your Message</Label>
        <Textarea
          value={message}
          onChange={e => setMessage(e.target.value)}
          name='message'
          id='contact-form-message'
        />
      </Box>
      <Button variant={'primary'} type='submit' required>
        Submit {submitting && <Spinner size='20' />}
      </Button>
    </form>
  )
}

export default ContactForm

ContactForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  success: PropTypes.bool
}
