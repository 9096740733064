import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='Our Commitment' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
      We take our commitment to our users seriously. If you need our help navigating our resources, have questions about how to use the site, are experiencing any technical difficulties, or want to know more about the Franciscan Intellectual and Spiritual Tradition, please do not hesitate to contact us.

      </Text>
    </Card>
  </Section>
)

export default Commitment
